import type { ICompany } from 'config'
import { IUser } from 'pages/ManageAccounts'

export interface IProduct {
  Id: number
  Description: string
  Type: string
  IsInterestOnly: boolean
  Term: number
  Power: boolean
  index?: number
}

export interface ILockDay {
  description: string
  Power: boolean
}

export interface ILoanAmountBonusMargin {
  from: number
  to: number
  margin: number
}

export interface IBonusMargin {
  loanAmount: ILoanAmountBonusMargin[]
}

export interface IRatePriceLimitWithExitFee {
  exitFee: IRange
  rate: IMinMaxLimit
  price: IMinMaxLimit
}

export interface IProgram {
  ID: number
  Name: string
  Type: string
  OriginFile: boolean
  Margin: number
  BonusMargin: IBonusMargin
  RatePriceLimitWithExitFee?: IRatePriceLimitWithExitFee[]
  MinMaxRate?: IMinMaxLimit
  LTV: IRange[]
  InterestRate: number[]
  ArmBasePrice: number[]
  FixedBasePrice: number[]
  IncomeDocs: IIncomeDoc[]
  LLPAs: ILLPAs[]
  Power: boolean
  OtherTypeProgramData: Record<string, any>
  Restriction?: IRestriction
}

export interface IRange {
  from: number
  to: number
}

export interface IIncomeDoc {
  IncomeDocItems: Array<string>
  LLPA: Array<ILLPA>
}

export interface IRestriction {
  [key: string]: any
}

export interface ILLPAs {
  ID: string
  Name: string
  Values: Array<ILLPA>
}

export interface ILLPA {
  Item: any
  LLPA: Array<number>
}

export interface IRangeLLPA {
  Item: IRange
  LLPA: number[]
}

export interface INormalLLPA {
  Item: string
  LLPA: number[]
}

export interface IMasterProgram {
  ID: number
  Name: string
  Level: number
  ProgramIDs: number[]
}

export interface IRateSheet {
  loanId: number
  ratesheetDate: string
  createdBy: string
  products: IProduct[]
  lockDays: ILockDay[]
  bankruptcy: boolean
  programs: IProgram[]
  masterPrograms?: IMasterProgram[]
  isTemp: boolean
  company: ICompany
}

export interface IProgramInfo {
  ID: number
  Name: string
  LtvLimit?: Record<string, number>
}

export interface IinEligibleObject {
  name: string
  value: string
  description: string
  ltvMaxCalcHistory?: string[]
}

export interface IinEligibleProduct {
  [key: string]: Array<IinEligibleObject>
}

export interface ICommercialLtvData {
  title: string
  maxValue: number
  value: number
  message: string
}

export interface ILockTermPrice {
  LockDay: string
  BasePrice: number
  LockTermLLPA: number
  Price: string
  Escrow: null
}

export interface ILtvLimit {
  max_aiv_ltv?: number
  max_arv_ltv?: number
  max_ltc?: number
  max_ltp?: number
  aiv_ltv?: number
  arv_ltv?: number
  ltc?: number
  ltp?: number
}

export interface ILockDayPrice {
  Rate: number
  BaseRate: number
  Dscr?: number
  RateSpread?: number
  ExitFee?: number
  LockTermPrices: ILockTermPrice[]
  CalcHistory?: string[]
  LLPA?: Record<string, ILLPAData>
  Limit?: ILtvLimit
  SubProgramID?: number
}

export interface ILLPAData {
  name: string
  isEnabled: boolean
  value: number
  label?: string
  from?: number
  to?: number
}

export interface IProgramResponse {
  ProgramID: number
  ProgramName: string
  Margin: number
  Prices: ILockDayPrice[]
  LTV: IRange
}

export interface IPricesData {
  ProductID: number
  ProductName: string
  Programs: IProgramResponse[]
}

export interface IPriceLoanRlt {
  RatesheetID: number
  FastMoneyLimits: Record<string, any>
  Prices: IPricesData[]
  Programs: IProgramInfo[]
  LockDays: ILockDay[]
  MortgageProducts: IProduct[]
  InEligibleProducts: IinEligibleProduct
  loanID: number
}

enum PPCalculation {
  NoRestriction = 'NoRestriction',
  NA = 'NA', // Not Applicable
}

enum PPMaxterm {
  NA = 'NA', // Not Applicable
  None = 'None',
  Blank = 'Blank',
}

enum PPApplicability {
  NA = 'NA',
  Refinance = 'Refinance',
  Purchase = 'Purchase',
  All = 'All',
  Blank = 'Blank',
}

export interface IPrepayPenalty {
  calculation: PPCalculation | string
  maxTerm: { Purchase: number; Refinance: number } | PPMaxterm | string | number
  applicability: PPApplicability
  other?: string
}

export interface IHistory {
  by: string
  program: string
  time: string
  data: string
}

export interface ILtvData {
  loanAmount: IRange
  monthsReserve: number
  ficos: number[]
  purchaseRT: number[]
  nocashout: number[]
  cashout: number[]
}

export interface ILTVs {
  title: string
  data: ILtvData[]
}

export interface ILtvLimitsData {
  label: string
  occupancy: string
  bigDscrLtvs?: {
    title: string
    data: ILtvData[]
  }[]
  ltvs: ILTVs[]
  conditions: {
    propertyType: {
      properties: string[]
      purchaseRT: number[]
      cashout: number[]
    }
  }
  creditHousingLogic: string[]
  overlayLogic: string[]
  propertyTypeLogic: string[]
  limitedCredit: string[]
  expandedDTI: string[]
  conditionLabels: {
    creditHousingLogic: string
    overlayLogic: string
    propertyTypeLogic: string
    limitedCredit: string
    expandedDTI: string
  }
}

export interface ILoanAmountRange {
  index: number
  from: number
  to: number
  status?: string
  key?: 'ltvs' | 'bigDscrLtvs'
}

export interface ITableValue {
  index: number
  index1: number
  index2: number
  key: keyof ILtvData // string
  value: any
  subkey?: 'ltvs' | 'bigDscrLtvs'
}

export interface IFicoRemove {
  index: number
  index1: number
  value: number
  key?: 'ltvs' | 'bigDscrLtvs'
}

export interface ILtvValues {
  aivLtv: number
  arvLtv: number
  ltc: number
  ltp: number
}

export interface IRatePriceAdjustmentValue {
  rate: number
  price: number
}

interface ILabelValue {
  label: string
  value: string
}

export interface IRehabBudgetTypeOption1 {
  lightRate: number
  lightTo: number
  standardRate: number
  standardFrom: number
  standardTo: number
  heavyRate: number
  heavyFrom: number
}

export interface IRehabBudgetTypeOption2 {
  lightRate: number
  lightTo: number
  heavyRateFrom: number
  heavyRateTo: number
  heavyFrom: number
  heavyArvFrom: number
}

export interface IRehabBudgetTypeOption3 {
  lightRate: number
  heavyRateFrom: number
  heavyRateTo: number
  heavyArvFrom: number
}

export enum RehabBudgetTypeOption {
  OPTION1 = 'opt1',
  OPTION2 = 'opt2',
  OPTION3 = 'opt3',
}

export interface IRehabBudgetTypeDefinition {
  opt1: IRehabBudgetTypeOption1
  opt2: IRehabBudgetTypeOption2
  opt3: IRehabBudgetTypeOption3
}

export interface IRehabBudgetType {
  id: number
  company: ICompany
  programID?: number
  isGeneral: boolean
  option: RehabBudgetTypeOption
  definition: IRehabBudgetTypeDefinition
  createdAt: Date
  updatedAt: Date
}

export interface IRatePriceAdjustment {
  purchase: IRatePriceAdjustmentValue[]
  nocashout: IRatePriceAdjustmentValue[]
  cashout: IRatePriceAdjustmentValue[]
}

export interface IMaxCashToBorrowerLeverageLimit {
  ltv: IRange
  maxValue: number
  loanAmount: IMinMaxLimit
  minDSCR: number
  minFICO: number
}

export interface IMinMaxLimit {
  min: number
  max: number
}

export interface ICrossCollateralBlanket {
  numberOfPropertiesLimit: IMinMaxLimit
  propertyLevelAllocatedBalanceLimit: IMinMaxLimit
  eligibleOccupancies: string[]
  eligiblePropertyTypes: string[]
  minAsIsValue: number
  minPurchasePrice: number
  minDscr: {
    propertyType: string
    amortizingPaymentMinDscr: number
    interestOnlyMinDscr: number
  }[]
}

export interface INonDscrLTVs {
  aivLtv: number
  arvLtv: number
  ltc: number
  ltp: number
  cltv: number
}

export interface ISecondLienLimit {
  maxCLTV: number
  maxSecondLien: number
}

// Non-DSCR leverage limit interfaces
export interface IExperienceLtvMaxLimits {
  from: number
  to: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IPropertyTypeLeverageData {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: INonDscrLTVs
      nocashout: INonDscrLTVs
      cashout: INonDscrLTVs
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface ICitizenshipLtvMaxLimits {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    minExperience: number
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IValueLeverageLimit {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IFirstTimeInvestorValueLeverageLimit {
  value: string
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IFirstTimeInvestorLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
  mortgageLates: IFirstTimeInvestorValueLeverageLimit[]
  fcSsDil: IFirstTimeInvestorValueLeverageLimit[]
  bankruptcy: IFirstTimeInvestorValueLeverageLimit[]
}

export interface IFirstTimeInvestorLeverageData {
  label: string
  limits: IFirstTimeInvestorLimit[]
}

export interface IRuralPropertyLeverageLimit {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    propertyTypes: string[]
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IRehabBudgetTypeLimit {
  expRange: IRange
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IRehabBudgetTypeLtvMaxLimits {
  value: string
  limits: IRehabBudgetTypeLimit[]
}

export interface IFicoLeverageLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  LTV: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface ILoanAmountLeverageLimit {
  loanAmount: IRange
  LTV: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

export interface ILeverageMaxLimits {
  experienceLtvLimits: IExperienceLtvMaxLimits[]
  propertyTypeLtvLimits: IPropertyTypeLeverageData[]
  occupancyLeverageLimit: IValueLeverageLimit[]
  bankruptcyLeverageMaxLimit: IValueLeverageLimit[]
  fcSsDilLeverageMaxLimit: IValueLeverageLimit[]
  mortgageLatesLtvLimits: IValueLeverageLimit[]
  forbearanceLeverageLimit: IValueLeverageLimit[]
  decliningMarketPropertyLeverageLimit: IValueLeverageLimit[]
  newConstructionPropertyLeverageLimit: IValueLeverageLimit[]
  citizenshipLtvLimits: ICitizenshipLtvMaxLimits[]
  ruralPropertyLeverageMaxLimit: IRuralPropertyLeverageLimit[]
  rehabBudgetTypeLtvLimits: IRehabBudgetTypeLtvMaxLimits[]
  ficoLeverageLimit: IFicoLeverageLimit[]
  firstTimeInvestorLeverageMaxLimit: IFirstTimeInvestorLeverageData[]
  loanAmountLeverageLimit: ILoanAmountLeverageLimit[]
  maxCashToBorrowerLeverageMaxLimit: IMaxCashToBorrowerLeverageLimit[]
  crossCollateralBlanket: ICrossCollateralBlanket
  secondLienMaxLimit: ISecondLienLimit
}

// DSCR leverage limit interfeaces
export interface IDscrLTVs {
  ltv: IMinMaxLimit
  cltv: number
}

export interface IDscrPropertyTypeLeverageData {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IDscrFicoLeverageLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  LTV: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrValueLeverageLimit {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IDscrRuralPropertyLeverageLimit {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
    propertyTypes: string[]
  }[]
}

export interface IDscrFirstTimeInvestorValueLeverageLimit {
  value: string
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrFirstTimeInvestorLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
  mortgageLates: IDscrFirstTimeInvestorValueLeverageLimit[]
  fcSsDil: IDscrFirstTimeInvestorValueLeverageLimit[]
  bankruptcy: IDscrFirstTimeInvestorValueLeverageLimit[]
}

export interface IDscrFirstTimeInvestorLeverageData {
  label: string
  limits: IDscrFirstTimeInvestorLimit[]
}

export interface IInterestOnlyLimit {
  fico: IRange
  minDscr: number
  minMonthsReserve: number
  minLoanAmount: number
  maxLoanAmount: number
  minLTV: number
  maxLTV: number
  maxCLTV: number
}

export interface IDscrInterestOnlyLeverageLimit {
  purchase: IInterestOnlyLimit[]
  nocashout: IInterestOnlyLimit[]
  cashout: IInterestOnlyLimit[]
}

export interface IDscrRangeLeverageLimit {
  dscr: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minFICO: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLoanAmountLeverageLimit {
  loanAmount: IRange
  LTV: {
    purchase: number
    nocashout: number
    cashout: number
  }
  CLTV: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLeverageMaxLimits {
  OccupancyLeverageLimit: IDscrValueLeverageLimit[]
  PropertyTypeLtvMaxLimit: IDscrPropertyTypeLeverageData[]
  EscrowTypeLeverageLimit: IDscrValueLeverageLimit[]
  CitizenshipLtvMaxLimit: IDscrValueLeverageLimit[]
  MortgageLatesLtvMaxLimit: IDscrValueLeverageLimit[]
  FcSsDilLeverageMaxLimit: IDscrValueLeverageLimit[]
  BankruptcyLeverageMaxLimit: IDscrValueLeverageLimit[]
  ForbearanceLeverageLimit: IDscrValueLeverageLimit[]
  IsShortTermRentalLeverageLimit: IDscrValueLeverageLimit[]
  DecliningMarketPropertyLeverageLimit: IDscrValueLeverageLimit[]
  NewConstructionPropertyLeverageLimit: IDscrValueLeverageLimit[]
  RuralPropertyLeverageMaxLimit: IDscrRuralPropertyLeverageLimit[]
  FirstTimeInvestorLeverageMaxLimit: IDscrFirstTimeInvestorLeverageData[]
  InterestOnlyLeverageLimit: IDscrInterestOnlyLeverageLimit
  FICOLeverageLimit: IDscrFicoLeverageLimit[]
  DscrLeverageLimit: IDscrRangeLeverageLimit[]
  LoanAmountLeverageLimit: IDscrLoanAmountLeverageLimit[]
  MaxCashToBorrowerLeverageMaxLimit: IMaxCashToBorrowerLeverageLimit[]
  CrossCollateralBlanket: ICrossCollateralBlanket
  SecondLienMaxLimit: ISecondLienLimit
}

export interface IValueLeverageData {
  value: string
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

// Non DSCR Leverage Adjustments
export interface IFicoLeverageAdjustment {
  range: IRange
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  propertyTypes: string[]
}

export interface IRangeLeverageAdjustment {
  range: IRange
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

export interface ILeverageAdjustments {
  occupancyLeverageAdjustment: IValueLeverageData[]
  propertyTypeLeverageAdjustment: IValueLeverageData[]
  mortgageLatesLeverageAdjustment: IValueLeverageData[]
  fcSsDilLeverageAdjustment: IValueLeverageData[]
  bankruptcyLeverageAdjustment: IValueLeverageData[]
  forbearanceLeverageAdjustment: IValueLeverageData[]
  underConstructionLeverageAdjustment: IValueLeverageData[]
  isPropertyInLeasableStateLeverageAdjustment: IValueLeverageData[]
  decliningMarketPropertyLeverageAdjustment: IValueLeverageData[]
  newConstructionPropertyLeverageAdjustment: IValueLeverageData[]
  exitFeeLeverageAdjustment: IRangeLeverageAdjustment[]
  ficoLeverageAdjustment: IFicoLeverageAdjustment[]
  loanAmountLeverageAdjustment: IRangeLeverageAdjustment[]
  monthsReserveLeverageAdjustment: IRangeLeverageAdjustment[]
  experienceLeverageAdjustment: IRangeLeverageAdjustment[]
  citizenshipLeverageAdjustment: IValueLeverageData[]
  rehabBudgetTypeLeverageAdjustment: IValueLeverageData[]
  ruralPropertyLeverageAdjustment: IValueLeverageData[]
  firstTimeHomeBuyerLeverageAdjustment: IValueLeverageData[]
  firstTimeInvestorLeverageAdjustment: IValueLeverageData[]
}

export interface IDscrValueLeverageData {
  value: string
  limit: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrRangeLeverageAdjustment {
  range: IRange
  limit: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLeverageAdjustments {
  OccupancyLeverageAdjustment: IDscrValueLeverageData[]
  LoanAmountLeverageAdjustment: IDscrRangeLeverageAdjustment[]
  PropertyTypeLeverageAdjustment: IDscrValueLeverageData[]
  MortgageLatesLeverageAdjustment: IDscrValueLeverageData[]
  FcSsDilLeverageAdjustment: IDscrValueLeverageData[]
  BankruptcyLeverageAdjustment: IDscrValueLeverageData[]
  ForbearanceLeverageAdjustment: IDscrValueLeverageData[]
  IsPropertyInLeasableStateLeverageAdjustment: IDscrValueLeverageData[]
  IsShortTermRentalLeverageAdjustment: IDscrValueLeverageData[]
  DecliningMarketPropertyLeverageAdjustment: IDscrValueLeverageData[]
  NewConstructionPropertyLeverageAdjustment: IDscrValueLeverageData[]
}

export interface ICommercialOnlyProgrammed {
  leverageMaxLimits?: ILeverageMaxLimits
  leverageAdjustments?: ILeverageAdjustments
  baseRates: number[]
  basePrices: number[]
  rateSpreads: number[]
  exitFees: number[]
  ltvLimits: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  maxRatePriceAdjustment: {
    purchase: IRatePriceAdjustmentValue
    nocashout: IRatePriceAdjustmentValue
    cashout: IRatePriceAdjustmentValue
  }
  experience: string[]
  experienceAdjustment: IRatePriceAdjustment
  loanTerm: number[]
  loanTermAdjustment: IRatePriceAdjustment
  aivLtvs: number[]
  aivLtvAdjustment: IRatePriceAdjustment
  ltcs: number[]
  ltcAdjustment: IRatePriceAdjustment
  ficos: IRange[]
  ficoAdjustment: IRatePriceAdjustment
  monthsReserve: IRange[]
  monthsReserveAdjustment: IRatePriceAdjustment
  propertyTypes: string[]
  propertyTypeAdjustment: IRatePriceAdjustment
  bankruptcy: ILabelValue[]
  bankruptcyAdjustment: IRatePriceAdjustment
  foreclosure: ILabelValue[]
  foreclosureAdjustment: IRatePriceAdjustment
  deedInLieu: ILabelValue[]
  deedInLieuAdjustment: IRatePriceAdjustment
  shortsale: ILabelValue[]
  shortsaleAdjustment: IRatePriceAdjustment
  mortgageLates: ILabelValue[]
  mortgageLatesAdjustment: IRatePriceAdjustment
  forbearance: string[]
  forbearanceAdjustment: IRatePriceAdjustment
  citizenships: ILabelValue[]
  citizenshipAdjustment: IRatePriceAdjustment
  rehabBudgetTypes: ILabelValue[]
  rehabBudgetTypesAdjustment: IRatePriceAdjustment
  loanAmount: IRange
}

export interface IStateCitiesCounties {
  [key: string]: string[]
}

export interface IExperienceTierLimit {
  expRange: IRange
  minFico: number
  maxLoanAmount: number
}

export interface IExperienceCityCountyTier {
  title: string
  values: IStateCitiesCounties
  limit: IExperienceTierLimit[]
}

export interface IExperienceMsaTier {
  title: string
  values: IStateMSA[]
  limit: IExperienceTierLimit[]
}

export interface IExperienceTier {
  city: IExperienceCityCountyTier[]
  county: IExperienceCityCountyTier[]
  msa: IExperienceMsaTier[]
}

export interface ICityCountyTier {
  title: string
  values: IStateCitiesCounties
  ltvAdjustment: ILtvValues
}

export interface IMSA {
  name: string
  ranking: number
}

export interface IStateMSA {
  states: string[]
  msa: IMSA
}

export interface IStateTier {
  id: number
  programName: string
  programID: number
  cityTiers: ICityCountyTier[]
  countyTiers: ICityCountyTier[]
  msaTiers: IMsaTier[]
  experienceTiers: IExperienceTier
  createdAt: Date
  updatedAt: Date
}

export interface IPropertyTypeLtvAdj {
  propertyType: string
  unitsRange: IRange
  aivValue: IRange
  arvValue: IRange
  ltvAdjustment: ILtvValues
}

export interface IMsaTier {
  title: string
  values: IStateMSA[]
  propertyTypeLtvAdj: IPropertyTypeLtvAdj[]
}

export interface IPrepaymentPenaltyOverlay {
  label: string
  minPPP: number
}

export interface ILoan {
  id: number
  no: number
  user: IUser
  ratesheet: IRateSheet
  loanName: string
  productType: string
  transactionType: string
  proposedOccupancy: string
  loanTerm: number
  proposedLoanAmount: number
  propertyPurchasePrice: number
  asIsValue: number
  afterRepairValue: number
  rehabBudget: number
  interestReserve: number
  constructionReserve: number
  lienPayoff: number
  closingCostEstimate: number
  experience: string
  howManyExperiences: number
  proposedMonthlyTaxes: number
  proposedMonthlyInsurance: number
  proposedMonthlyRent: number
  proposedMonthlyHoaDues: number
  floodInsurance: number
  schoolTax: number
  otherTax: number
  propertyType: string
  condoType: string
  prepaymentPenalty: number
  residency: string
  amortizationType: string
  escrowType: string
  firstTimeHomeBuyer: boolean
  firstTimeHomeInvestor: boolean
  propertyUnderConstruction: boolean
  isPropertyInLeasableState: boolean
  isShortTermRental: boolean
  propertyHasBeenListed: boolean
  ruralProperty: boolean
  decliningMarketProperty: boolean
  isNewConstructionProperty: boolean
  bankruptcy: string
  foreclosure: string
  deedInLieu: string
  shortSale: string
  mortgageLates: string
  forbearanceLoanModification: string
  secondLien: number
  secondLienPayment: number
  monthsReserve: number
  estimatedDscr: number
  numberOfUnits: number
  entityTitle: string
  estimatedCreditScore: number
  propertyState: string
  isCEMATransaction: boolean
  subjectPropertyAddress: string
  additionalPropertyAddress: string[]
  ltvAdjustment: number
  cltvAdjustment: number
  aivLtvAdjustment: number
  arvLtvAdjustment: number
  ltcAdjustment: number
  ltpAdjustment: number
  createdAt: Date
  updatedAt: Date
}
